<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tim.other_allowance') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="circular_memo_no"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_memo_no"
                            :options="circularList"
                            id="circular_memo_no"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="batch_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_config.trainee_type')}}
                      </template>
                        <b-form-select
                          plain
                          v-model="search.other_trainee_type_id"
                          :options="traineeTypeList"
                          id="other_trainee_type_id"
                          >
                          <template v-slot:first>
                                <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col> -->
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tim.other_allowance') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link to="other-allowance-form" :class="'btn btn_add_new'">
                        <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                      </router-link>
                    </template>
                    <!-- <template v-slot:headerAction>
                      <router-link to="other-trainee-trainer-registration-form" :class="'btn btn-success text-light'">{{  $t('globalTrans.add_new') }}</router-link>
                    </template> -->
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(other_trainee_type)="data">
                                          <span class="capitalize">{{ getOtherTraineeTypeName(data.item.other_trainee_type_id) }}</span>
                                        </template>
                                        <template v-slot:cell(attachment)="data">
                                          <a target = '_blank' v-if="data.item.attachment" :href="trainingElearningServiceBaseUrl + 'storage/uploads/attachment/' + data.item.attachment" download>({{$t('globalTrans.view_download')}})</a>
                                          <!-- <b-img width='100px' :src="trainingElearningServiceBaseUrl+'storage/'+data.item.attachment" fluid alt="Right Logo"></b-img> -->
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.save')}}</span>
                                          <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button variant="btn btn-sm btn-success" v-if="data.item.status == 1" size="sm" @click="finalSave(data.item)" ><i class="far fa-check-square m-0"></i></b-button> -->
                                            <!-- <b-button v-b-modal.modal-4 variant="btn btn-sm btn-info" size="sm" @click="edit(data.item)">
                                              <i class="fas fa-eye"></i>
                                            </b-button> -->
                                            <!-- {{$t('globalTrans.attachment')}}  -->
                                            <a href="javascript:" :class="'btn btn_table_action table_action_edit'"  v-b-modal.modal-4  size="sm" @click="edit(data.item)"><i class="fas fa-paperclip"></i></a>
                                            <router-link :to="`other-allowance-details?circilarMemoNo=${data.item.circular_memo_no}&payment_type=Trainee&otherTraineeType=${data.item.other_trainee_type_id}`" :class="'btn btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                            <!-- <router-link v-if="data.item.status == 1" title="Edit" :to="`/training-e-learning-service/tpm/course-module-form?id=${data.item.id}`" class=" btn btn-sm btn-warning"><i class="ri-ball-pen-fill m-0"></i></router-link> -->
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <!-- <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.other_trainee_trainer_registration') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal> -->
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentHonorarumListOther, circularList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import Details from './Details'
import Store from '@/store'
import Form from './FormV'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form
    },
    data () {
        return {
          circularLoading: false,
          search: {
            org_id: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            other_trainee_type_id: 0,
            circular_memo_no: 0,
            training_status: 2,
            payment_type: 'Trainee',
            fiscal_year_id: 0
          },
          circularList: [],
          trainingTypeList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          trainingCategoryList: [],
          trainingTitleList: [],
          officeTypeList: [],
          allBatchListData: [],
          otherTraineeTypeList: [],
          traineeTypeList: []
        }
    },
    computed: {
      // trainingTypeList: function () {
      //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      // },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return this.$t('elearning_tim.other_allowance')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            //   { label: this.$t('globalTrans.course'), class: 'text-left' },
            //   { label: this.$t('elearning_tpm.course_module'), class: 'text-left' },
            //   { label: this.$t('elearning_tpm.course_module_details'), class: 'text-left' },
              { label: this.$t('elearning_config.organization'), class: 'text-left' },
              { label: this.$t('elearning_config.training_title'), class: 'text-left' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
              { label: this.$t('elearning_config.other_trainee_type'), class: 'text-left' },
               { label: this.$t('globalTrans.attachment'), class: 'text-center' },
            //   { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
            //   { key: 'course_name_bn' },
            //   { key: 'course_module_bn' },
            //   { key: 'course_module_details_bn' },
              { key: 'org_bn' },
              { key: 'training_title_bn' },
              { key: 'circular_memo_no' },
              { key: 'other_trainee_type' },
              { key: 'attachment' },
            //   { key: 'status' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
            //   { key: 'course_name' },
            //   { key: 'course_module' },
            //   { key: 'course_module_details' },
              { key: 'org' },
              { key: 'training_title' },
              { key: 'circular_memo_no' },
              { key: 'other_trainee_type' },
              { key: 'attachment' },
            //   { key: 'status' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
        if (this.$store.state.Auth.activeRoleId !== 1) {
          this.search.org_id = this.$store.state.Auth.authUser.org_id
          this.search.office_type_id = this.$store.state.Auth.authUser.office_type_id
          this.search.office_id = this.$store.state.Auth.authUser.office_id
        }
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'search.org_id': function (newValue, oldValue) {
        this.trainingTypeList = this.getTypeList()
        if (newValue !== oldValue) {
          if (this.$store.state.Auth.activeRoleId === 1) {
            this.officeTypeList = this.getOfficeTypeList(newValue)
          }
        }
      },
      'search.training_title_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getCircularList()
        }
      }
      // 'search.circular_memo_no': function (newValue) {
      //   this.getOtherTraineeType()
      // }
    },
    methods: {
        getOfficeTypeList (orgId) {
          const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
          if (orgId) {
            return OfficeTypeList.filter(item => item.org_id === orgId)
          }
          return OfficeTypeList
        },
        getOfficeList (officeTypeId) {
          const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
          if (officeTypeId) {
            return officeList.filter(item => item.office_type_id === officeTypeId)
          }
          return officeList
        },
        getOtherTraineeTypeName (id) {
          const otherTraineeType = this.$store.state.TrainingElearning.commonObj.otherTraineeTypeList.filter(item => item.status === 1).find(item => item.value === id)
          if (this.$i18n.locale === 'bn') {
              return otherTraineeType !== undefined ? otherTraineeType.text_bn : ''
          } else {
              return otherTraineeType !== undefined ? otherTraineeType.text_en : ''
          }
        },
        async getOtherTraineeType () {
        this.circularLoading = true
        const serchData = {
          org_id: this.search.org_id,
          fiscal_year_id: this.search.fiscal_year_id,
          office_id: this.search.office_id,
          office_type_id: this.search.office_type_id,
          circular_memo_no: this.search.circular_memo_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/circular-publications/other-trainee-type' + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
          this.otherTraineeTypeList = []
        } else {
          this.otherTraineeTypeList = result.otherTraineeTypeList
          this.getTraineeType()
        }
        this.circularLoading = false
      },
      getTraineeType () {
        const typeData = this.otherTraineeTypeList.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.other_trainee_type_bn }
          } else {
            return { value: obj.id, text: obj.other_trainee_type }
          }
        })
        if (typeData) {
          this.traineeTypeList = typeData
        } else {
          this.traineeTypeList = []
        }
      },
      async getCircularList () {
        if (this.search.fiscal_year_id && this.search.training_title_id) {
          this.circularLoading = true
          const serchData = {
            fiscal_year_id: this.search.fiscal_year_id,
            training_type_id: this.search.training_type_id,
            training_category_id: this.search.training_category_id,
            training_title_id: this.search.training_title_id,
            office_id: this.search.office_id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
          if (!result.success) {
            this.allBatchListData = []
          } else {
            this.allBatchListData = result.batchList
            this.circluarList()
          }
          this.circularLoading = false
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      getTypeList () {
        if (this.search.org_id) {
          const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
          if (this.search.org_id) {
            return type.filter(item => item.org_id === parseInt(this.search.org_id))
          }
          return type
        }
      },
      finalSave (item) {
        // this.toggleStatusCustom2(trainingElearningServiceBaseUrl, courseModuleFinalSave, item)
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
        //   this.changeStatus(trainingElearningServiceBaseUrl, courseModuleToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, billPaymentHonorarumListOther, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
